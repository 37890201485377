import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, InputNumber, Input, message, Space } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';

const { Option } = Select;

const EditEntryForm = ({ visible, onCancel, onSuccess, entry }) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [entryNames, setEntryNames] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && entry) {
      fetchDepartments();
      fetchEntryNames(entry.department.id);
      form.setFieldsValue({
        department: entry.department.id,
        entry_name: entry.entry_name.id,
        value: entry.value,
        entry_date: dayjs(entry.entry_date).format('YYYY-MM-DD'),
      });
    }
  }, [visible, entry, form]);

  const fetchDepartments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/user-departments/', {
        headers: { Authorization: `Token ${token}` }
      });
      setDepartments(response.data);
    } catch (error) {
      message.error('Failed to fetch departments');
    }
  };

  const fetchEntryNames = async (departmentId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api-report-management-v3.uttarakhand.tech/api/entry-names/?department_id=${departmentId}`, {
        headers: { Authorization: `Token ${token}` }
      });
      setEntryNames(response.data);
    } catch (error) {
      message.error('Failed to fetch entry names');
    }
  };

  const handleDepartmentChange = (value) => {
    form.setFieldsValue({ entry_name: undefined });
    fetchEntryNames(value);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const requestData = {
        department: values.department,
        entry_name: values.entry_name,
        value: values.value,
        entry_date: values.entry_date
      };

      console.log('Submitting data:', requestData);

      const response = await axios.put(
        `https://api-report-management-v3.uttarakhand.tech/api/update-report-entry/${entry.id}/`, 
        requestData,
        {
          headers: { 
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Backend response:', response.data);

      message.success('Entry updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Error details:', error.response?.data);
      message.error('Failed to update entry: ' + (error.response?.data?.detail || 'Please try again.'));
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={visible}
      title="Edit Entry"
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={loading}
      okText="OK"
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item 
          name="department" 
          label={<><span style={{ color: 'red' }}>*</span> Department</>}
          rules={[{ required: true, message: 'Please select a department' }]}
        >
          <Select placeholder="Select Department" onChange={handleDepartmentChange}>
            {departments.map(dept => (
              <Option key={dept.id} value={dept.id}>{dept.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item 
          name="entry_name" 
          label={<><span style={{ color: 'red' }}>*</span> Entry Name</>}
          rules={[{ required: true, message: 'Please select an entry name' }]}
        >
          <Select placeholder="Select Entry Name">
            {entryNames.map(entry => (
              <Option key={entry.id} value={entry.id}>{entry.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item 
          name="value" 
          label={<><span style={{ color: 'red' }}>*</span> Value</>}
          rules={[{ required: true, message: 'Please input a value' }]}
        >
          <InputNumber placeholder="Value" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item 
          name="entry_date" 
          label={<><span style={{ color: 'red' }}>*</span> Update Entry Date</>}
          rules={[
            { required: true, message: 'Please enter a date' },
            {
              pattern: /^\d{4}-\d{2}-\d{2}$/,
              message: 'Please enter a valid date in YYYY-MM-DD format'
            }
          ]}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input 
              type="date"
              style={{ width: '100%' }} 
            />
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditEntryForm;