import React, { useState, useEffect, useCallback } from 'react';
import { Card, DatePicker, Spin, Alert, Empty, Typography, Row, Col } from 'antd';
import axios from 'axios';
import { ResponsiveCalendar } from '@nivo/calendar';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const ReportAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([
    moment().startOf('month'),
    moment().endOf('month')
  ]);

  const fetchAnalyticsData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const [startDate, endDate] = dateRange;
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/report-analytics/', {
        headers: { Authorization: `Token ${token}` },
        params: {
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        },
      });
      console.log('API Response:', response.data);
      setAnalyticsData(response.data);
    } catch (error) {
      console.error('Failed to fetch analytics data:', error);
      setError(error.response?.data?.error || 'Failed to fetch analytics data. Please try again later.');
      if (error.response?.data?.details) {
        console.error('Error details:', error.response.data.details);
      }
    }
    setLoading(false);
  }, [dateRange]);

  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
    } else {
      setDateRange([
        moment().startOf('month'),
        moment().endOf('month')
      ]);
    }
  };

  const formatDataForCalendar = (departmentData) => {
    const formattedData = {};
    departmentData.entries.forEach((entry) => {
      const date = entry.date;
      if (!formattedData[date]) {
        formattedData[date] = 0;
      }
      formattedData[date] += entry.total_value;
    });
    return Object.entries(formattedData).map(([date, value]) => ({
      day: date,
      value,
    }));
  };

  const formatDataForLineChart = (departmentData) => {
    const entryNames = [...new Set(departmentData.entries.map(entry => entry.entry_name))];
    const dataByDate = departmentData.entries.reduce((acc, entry) => {
      if (!acc[entry.date]) {
        acc[entry.date] = { date: entry.date };
      }
      acc[entry.date][entry.entry_name] = entry.total_value;
      return acc;
    }, {});
    
    return {
      data: Object.values(dataByDate).sort((a, b) => moment(a.date).diff(moment(b.date))),
      entryNames
    };
  };

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE'];

  const renderDepartmentCard = (department) => {
    const { data, entryNames } = formatDataForLineChart(department);

    return (
      <Card
        key={department.department_name}
        title={department.department_name}
        style={{ marginBottom: 16 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div style={{ height: 200, marginBottom: 16 }}>
              <ResponsiveCalendar
                data={formatDataForCalendar(department)}
                from={dateRange[0].format('YYYY-MM-DD')}
                to={dateRange[1].format('YYYY-MM-DD')}
                emptyColor="#eeeeee"
                colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                  {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left'
                  }
                ]}
                tooltip={({ day, value }) => (
                  <div style={{ background: 'white', padding: '9px 12px', border: '1px solid #ccc' }}>
                    <strong>{day}</strong>
                    <br />
                    {department.entries
                      .filter((entry) => entry.date === day)
                      .map((entry) => (
                        <div key={entry.entry_name}>
                          {entry.entry_name}: {entry.total_value}
                        </div>
                      ))}
                  </div>
                )}
              />
            </div>
          </Col>
          <Col span={24}>
            <h3>Line Chart</h3>
            <div style={{ width: '100%', height: 400 }}>
              <ResponsiveContainer>
                <LineChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {entryNames.map((entryName, index) => (
                    <Line
                      key={entryName}
                      type="monotone"
                      dataKey={entryName}
                      stroke={colors[index % colors.length]}
                      activeDot={{ r: 8 }}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div>
      <h2>Report Analytics</h2>
      <RangePicker
        onChange={handleDateRangeChange}
        style={{ marginBottom: 16 }}
        disabledDate={(current) => current && current > moment().endOf('day')}
      />
      {error && (
        <Alert 
          message="Error" 
          description={error}
          type="error" 
          showIcon 
          style={{ marginBottom: 16 }} 
        />
      )}
      {loading ? (
        <Spin size="large" />
      ) : analyticsData.length > 0 ? (
        analyticsData.map(renderDepartmentCard)
      ) : (
        <Empty
          description={
            <Text>
              No data available for the selected date range. 
              Try selecting a different range or check if there are any entries for this period.
            </Text>
          }
        />
      )}
    </div>
  );
};

export default ReportAnalytics;