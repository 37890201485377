import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, DatePicker } from 'antd';
import axios from 'axios';

const { RangePicker } = DatePicker;

const DepartmentStatistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const fetchStatistics = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      let url = 'https://api-report-management-v3.uttarakhand.tech/api/department-statistics/';
      if (dateRange.length === 2) {
        const startDate = dateRange[0].format('YYYY-MM-DD');
        const endDate = dateRange[1].format('YYYY-MM-DD');
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` }
      });
      setStatistics(response.data);
    } catch (error) {
      console.error('Failed to fetch department statistics:', error);
    }
  }, [dateRange]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  const columns = [
    {
      title: 'Department',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Entry Names',
      dataIndex: 'entry_names',
      key: 'entry_names',
      render: (entry_names) => (
        <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
          {entry_names.map((entry, index) => (
            <li key={index}>
              {entry.name}: {entry.total_value}
            </li>
          ))}
        </ul>
      ),
    },
  ];

  const cardHeaderStyle = {
    backgroundColor: '#001529',
    color: 'white'
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  return (
    <Card 
      title="Department Statistics"
      headStyle={cardHeaderStyle}
      extra={
        <RangePicker 
          onChange={handleDateRangeChange}
          style={{ marginBottom: 16 }}
        />
      }
    >
      <Table 
        dataSource={statistics} 
        columns={columns} 
        pagination={false}
        rowKey="name"
      />
    </Card>
  );
};

export default DepartmentStatistics;